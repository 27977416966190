.content__wrapper {
    padding: 1rem 2rem;
    height: 100%;
    margin-top: 55px;
    z-index: 1;
}

.history__table {
    min-width: 500px;
    border-collapse: collapse;
    width: 100%;
}

.history__table td, .history__table th {
    border: 1px solid #aaa;
    padding: 5px 10px;
}

.history__table > tbody > tr:nth-child(odd){
    background-color: #e2e2e2;
}

.hidden {
    display: none;
}

.narrow {
    width: 50px;
}

.pre__text {
    font-family: var(--font-base);
    word-break: break-all;
    font-size: 10px;
}