.file__wrapper {
    flex: 1;
    height: calc(100vh - 90px);
    position: sticky;
    position: -webkit-sticky;
    top: 70px;
    left: 0;
}

.file__wrapper--iframe {
    width: 100%;
    height: 100%;
}