.nav {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    z-index: 2;
    width: 100%;
}

.nav__img {
    width: 250px;
}

.nav__links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

.nav__link {
    padding: 0 1rem;
}

.nav__link svg {
    margin-right: 8px;
}

