.content__wrapper {
    padding: 1rem 2rem;
    height: 100%;
    margin-top: 55px;
    z-index: 1;
}

.content__wrapper-loaded {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    min-height: 100%;
}