.file__dropzone {
    background-color: #e7e7e7;
    border: 6px dashed #ccc;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.25rem;
    cursor: pointer;
}

.file__dropzone > div {
    height: 100%;
    width: 100%;
}

.file__dropzone button {
    border: none;
    background-color: #e7e7e7;
    font-family: var(--font-base);
    font-size: 1.5rem;
}

.unstyled__ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.aside__warning {
    color: rgb(212, 55, 55);
}