@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --font-base: "Montserrat", sans-serif;
  --color-blue: rgb(0, 103, 166);
  --color-ltblue: rgb(0,157,255);
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  font-family: var(--font-base);
  height: calc(100% - 30px);
  font-size: .95rem;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: var(--color-blue);
}

a:hover {
  color: var(--color-ltblue);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-red {
  color: red;
}

.text-green {
  color: #14b56d;
}

.bold {
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-5 {
  margin-bottom: 12px;
}

.p-0 {
  padding: 0;
  margin: 0;
}

.align-end {
  align-self: flex-end;
}

.ul__small {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: .8rem;
}

.refs__title {
  font-size: .8rem;
  text-decoration: underline;
}

.select__disabled {
  border: 0 !important;
  background-color: inherit;
}

.loadDocument__received-row > td {
  background-color: #ccffe0;
  font-size: .725rem;
}

.mtb-30 {
  margin: 30px 0px !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-gap-10 {
  gap: 10px;
}

.split-output {
  padding: 1rem 2rem;
  color: #f2f2f2;
  background-color: rgba(0,0,0,.6);
  letter-spacing: 1.5px;
}

.small__orgName {
  color: #888;
  font-size: .65rem;
  margin: 0;
  padding: 0;
}

.block {
  display: block;
}