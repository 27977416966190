.load__wrapper {
    flex: 1;
}

.load__form--group {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.load__form--control {
    padding: .5rem 1rem;
    font-size: .75rem;
    border: 1px solid #ccc;
    flex: 2;
    font-family: var(--font-base);
}

.load__form--control-sm {
    padding: 0 .5rem;
    font-size: .9rem;
    border: 1px solid #ccc;
    font-family: var(--font-base);
}

.load__form--control-wide {
    width: 100%;
}

.load__form--label, 
.load__form--label-secondary {
    font-weight: 600;

    padding: 7px 10px;
    flex: 1;
    letter-spacing: 1.05px;
}

.load__form--label {
    
    align-self: stretch;
}

.load__form--label-secondary {
    background-color: var(--color-ltblue);
}

.load__form--hr {
    margin: 15px 0;
    height: 1px;
    background-color: #ccc;
    border: none;
}

.load__form--section {
    position: relative;
    /*border: 2px solid var(--color-ltblue);*/
    border: 1px solid #999;
    margin-top: 30px;
}

.load__form--section-title {
    position: absolute;
    top: -10px;
    left: 10px;
    z-index: 2;
    background-color: #f2f2f2;
    padding: 0 .75rem;
    font-size: 1rem;
    letter-spacing: 1.05px;
    font-weight: 600;
}

.load__form--section-title--toggler {
    position: absolute;
    top: -18px;
    left: 10px;
    z-index: 2;
    background-color: #f2f2f2;
    padding: 0 .75rem;
    font-size: 1rem;
    letter-spacing: 1.05px;
    font-weight: 600;
}

.load__form--section-content {
    padding: 1.5rem;
}

.load__form--table {
    width: 100%;
    margin: 5px 0 5px 0;
    border: 1px solid #ccc;
    border-collapse: collapse;
}

.load__form--table th, 
.load__form--table td {
    border: 1px solid #ccc;
    padding: 2px 4px;
}

.load__form--table-footer {
    font-weight: 600;
}

.load__form--table-footer-total {
    padding-left: 8px !important;
}

.load__form--table th {
    background-color: var(--color-ltblue);
    color: #fff;
}

.load__form--table-input {
    width: 100%;
    border: 1px solid #ccc;
    padding: .25rem;
    font-family: var(--font-base);
}

.load__form--btn {
    width: 100%;
    border: 0;
    background-color: #00a62c;
    padding: .8rem 2rem;
    color: #f7f7f7;
    font-family: var(--font-base);
    cursor: pointer;
}

.load__form--btn:hover {
    background-color: #00691c;
    transition: 0.5s ease;
}

.loadDetails__details {
    flex: 1;
    background-color: #e7e7e7;
    padding: 10px;
    align-self: center;
    min-height: 155px;
}

.load__form--ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.freightBillHeader__wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.flex-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.flex1 {
    flex: 1;
}

.load__form--table.load__form--table-docs > tbody > tr:nth-child(odd) {
    background-color: #e3f3fc;
}